import React, { useState } from 'react';
import { AutoevaluationDataResponse, PlanDataServiceInterfaceResponse, autoevaluationService } from '../services/autoevaluationService';
import { standarsItemsTeam3 } from '../utils/standarItems';
import ImprovementPlanDetailsModal from '../components/improvementPlan/ImprovementPlanDetailsModal';
import { EvidenceImprovementPlanDataResponse, GetEvidenceImprovementPlansByEmployee, uploadImageService } from '../services/uploadImageServices';
import EvidencePlansModal from '../components/improvementPlan/EvidencePlansModal';
import evidenceIcon from '../assets/icons/evidence-icon.svg'
import { UploadEvidenceModal } from './improvementPlan/UploadEvidence';

interface Column {
  id: 'item' | 'estandar' | 'fechaActualizacion' | 'vigencia' | 'estado' | 'evidencias' | 'planDeMejora';
  label: string;
  format?: (value: any) => string;
}

const columns: Column[] = [
  { id: 'item', label: 'Item' },
  { id: 'estandar', label: 'Estandar' },
  {
    id: 'fechaActualizacion',
    label: 'Fecha de Actualización',
    format: (value: number) => new Date(value).toLocaleDateString('es-ES'),
  },
  { id: 'vigencia', label: 'Vigencia' },
  { id: 'estado', label: 'Estado' },
  { id: 'evidencias', label: 'Evidencias' },
  { id: 'planDeMejora', label: 'Plan de Mejora' },
];

interface Data {
  item: string;
  estandar: string;
  fechaActualizacion: string;
  vigencia: number;
  estado: string;
  evidencias: string;
  planDeMejora: boolean;
}

function createData(
  item: string,
  estandar: string,
  fechaActualizacion: string,
  vigencia: number,
  evidencias: string,
  estado: string,
  planDeMejora: boolean
): Data {
  return { item, estandar, fechaActualizacion, vigencia, estado, evidencias, planDeMejora };
}

type TableItemsProps = {
  formData: AutoevaluationDataResponse | undefined;
  title: string
};

export interface EvidenceDetails {
  id: number;
  item_name: string;
  url: string;
  created_at: Date;
  minimum_standards: number;
}

const TableItems: React.FC<TableItemsProps> = ({ formData, title }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [evidenceModalOpen, setEvidenceModalOpen] = useState(false);
  const [uploadEvidenceModalOpen, setUploadEvidenceModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [propName, setPropName] = useState<string | undefined>(undefined);
  const [itemDetails, setItemDetails] = useState<PlanDataServiceInterfaceResponse>();
  const [evidenceDetails, setEvidenceDetails] = useState<EvidenceDetails[] | null>(null);

  if (!formData) {
    return null;
  }

  const rows = standarsItemsTeam3.map(field => {
    const value = (formData as any)[field.propName] || 'N/A';
    const firstSpaceIndex = field.text.indexOf(' ');
    return createData(
      field.text.substring(0, firstSpaceIndex),
      field.text.substring(firstSpaceIndex + 1),
      new Date(formData.created_at).toLocaleDateString('es-ES'),
      365,
      "Evidencia",
      value,
      value === 'No cumple'
    );
  });

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleOpenModal = (item: string, buttonType: 'Plan de Mejora' | 'Ver Evidencia') => {
    setSelectedItem(item);
    const itemObj = standarsItemsTeam3.find(field => `${field.text.substring(0, 5)} ${field.text.substring(5)}` === item);

    if (!itemObj) return;

    const propName = itemObj.propName;

    if (buttonType === 'Plan de Mejora') {
      autoevaluationService.getImprovementPlan(
        propName,
        (response) => {
          setItemDetails(response);
          setModalOpen(true);
        },
        (error) => {
          console.error('Error al obtener los datos:', error);
        }
      );
    } else if (buttonType === 'Ver Evidencia') {
      uploadImageService.getEvidenceImprovementPlans(
        new GetEvidenceImprovementPlansByEmployee(propName),
        (response: EvidenceImprovementPlanDataResponse[]) => {
          if (response.length === 0) {
            setUploadEvidenceModalOpen(true);
            setPropName(propName)
          } else {
            setEvidenceDetails(response);
            setEvidenceModalOpen(true);
          }
        },
        (error) => {
          console.error('Error al obtener los datos:', error);
        }
      );
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setUploadEvidenceModalOpen(false);
    setEvidenceModalOpen(false);
    setSelectedItem(undefined);
    setItemDetails(undefined);
  };

  return (
    <>
      <div className="overflow-x-auto max-h-[600px]">
        <table className="w-full bg-white border-separate border border-spacing-x-2 border-transparent">
          <thead className='sticky top-0 bg-white z-10'>
            <tr className="w-full">
              {columns.map((column) => (
                <th key={column.id} className='text-center px-4 py-2 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg'>
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-secondaryBrown text-center'>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <tr key={row.estandar} className="hover:bg-gray-50">
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <td key={column.id} className="px-6 py-3 text-sm">
                      {column.id === 'planDeMejora' ? (
                        <button
                          className={`text-primaryOrange ${!row.planDeMejora ? 'text-secondaryOrange    cursor-not-allowed' : ''}`}
                          onClick={() => handleOpenModal(row.item + ' ' + row.estandar, 'Plan de Mejora')}
                          disabled={!row.planDeMejora}
                        >
                          Plan de mejora
                        </button>
                      ) : column.id === 'evidencias' ? (
                        <button className="" onClick={() => handleOpenModal(row.item + ' ' + row.estandar, 'Ver Evidencia')}>
                          <img src={evidenceIcon} alt="Logo evidencias" />
                        </button>
                      ) : (
                        column.format && typeof value === 'number'
                          ? column.format(value)
                          : value
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      <div className="flex justify-between items-center py-2">
        <button
          className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
          onClick={() => handleChangePage(page - 1)}
          disabled={page === 0}
        >
          Anterior
        </button>
        <div>
          <label className='mr-2 text-primaryBrown'>Numero de filas</label>
          <select
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            className="border border-gray-300 rounded-md px-2 py-1"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={100}>100</option>
          </select>
        </div>
        <button
          className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
          onClick={() => handleChangePage(page + 1)}
          disabled={page >= Math.ceil(rows.length / rowsPerPage) - 1}
        >
          Siguiente
        </button>
      </div>
      <ImprovementPlanDetailsModal open={modalOpen} onClose={handleCloseModal} item={selectedItem} details={itemDetails} />
      <EvidencePlansModal open={evidenceModalOpen} onClose={() => setEvidenceModalOpen(false)} details={evidenceDetails} item={selectedItem} />
      <UploadEvidenceModal show={uploadEvidenceModalOpen} onClose={handleCloseModal} itemText={selectedItem} propName={propName} title={`Del item: ${selectedItem}`} />
    </>
  );
}

export default TableItems;
