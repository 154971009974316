/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { Card, Typography } from "@material-tailwind/react";
import AddImages from './AddImages';
import { EmployeesListData, employeesService } from '../../services/employeesService';
import { mainDivClassName } from '../../utils/MainDivClassName';
import HeaderSurveys from '../../components/HeaderSurveys';

type Props = {}
const TABLE_HEAD = ["Nombre", "Apellido", "Cédula", "Imágenes"];


const RegisterImages = (props: Props) => {
  const [employeesList, setEmployeesList] = useState<EmployeesListData[]>([]);

  useEffect(() => {
    employeesService.employeesData(
      (response) => {
        setEmployeesList(response.results)
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      },
      undefined,
      undefined,
      "Activo",
    );
  }, [])

  return (
    <>
      <div className={`grid place-items-start justify-items-center  h-screen  laptop:border laptop:border-white desktop:border desktop:border-white py-5 ${mainDivClassName}`} >
        <div className='max-w-[580px] min-h-[700px] bg-white rounded-lg shadow-lg border-2 p-2 border-secondaryBrown'>
          <HeaderSurveys title={`Evidencia puestos de trabajos`} />
          {employeesList &&
            <table className="w-full  overflow-auto  bg-white border-separate border border-spacing-x-2 border-transparent">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th key={head} className="text-center px-2 py-3 text-m  bg-secondaryGray text-secondaryBrown border-white rounded-lg">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                        placeholder={''}
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='text-secondaryBrown text-center'>
                {employeesList.map(({ first_name, last_name, id_card, company, id }, index) => (
                  <tr key={`employee_${id}`} className="hover:bg-gray-50">
                    <td className="px-2 py-3 text-m">
                      <Typography variant="small" color="blue-gray" className="font-normal" placeholder={''}>
                        {first_name}
                      </Typography>
                    </td>

                    <td className="px-2 py-3 text-m">
                      <Typography variant="small" color="blue-gray" className="font-normal" placeholder={''}>
                        {last_name}
                      </Typography>
                    </td>
                    <td className="px-2 py-3 text-m">
                      <Typography variant="small" color="blue-gray" className="font-normal " placeholder={''}>
                        {id_card}
                      </Typography>
                    </td>
                    <td className="px-2 py-3 text-m">
                      <AddImages employeeId={id} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    </>
  )
}
export default RegisterImages
