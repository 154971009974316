import { ReactElement, useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import { useNavigate } from 'react-router-dom';

interface PrivateRouteProps {
    children: ReactElement<any, any> | null;
    roles: string[]; // Roles permitidos para esta ruta
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, roles }) => {
    const navigate = useNavigate();
    const userState = useAppSelector((state) => state.user);
    const isAuthenticated = userState.isLoggedIn;
    const userType = userState.userType;

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        } else if (!roles.includes(userType)) {
            if (userType === "admin") {
                navigate('/admin/register-company');
            } else if (userType === "company") {
                navigate('/home');
            }
        }
    }, [isAuthenticated, userType, roles, navigate]);

    return children
};

export default PrivateRoute;
