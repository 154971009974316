import React from 'react';

interface TextFieldNumberProps {
  label: string;
  value: number | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
}

const TextFieldNumber: React.FC<TextFieldNumberProps> = ({ label, value, onChange, title }) => (
  <div className="flex flex-col mb-4 w-full">
    <label className="mb-1 text-m">{label}</label>
    <input
      type="number"
      title={title}
      value={value !== null ? value.toString() : ''}
      onChange={onChange}
      className="py-2 px-3 border text-m border-gray-300 rounded bg-gray-200 text-sm min-w-[10rem] max-w-[12rem"
    />
  </div>
);

export default TextFieldNumber;
