import React, { ReactNode } from 'react';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar'; // Asegúrate de importar Sidebar si es necesario

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {

  return (
    <div className="h-screen grid  grid-cols-[180px_1fr] grid-rows-[auto_1fr] max-w-[1920px]">
      <aside className="fixed top-0 h-screen w-[180px] ">
        <Sidebar />
      </aside>

      <header className="fixed  left-[180px] mobile:left-0 right-0  m-0 b-0">
        <Header />
      </header>

      <main className=" p-4 mt-[217px] tablet:mt-[307px] mobile:mt-[339px] overflow-auto h-[calc(100vh-203px)] col-start-2 row-start-2 mobile:col-start-1 mobile:col-span-2">
        {children}
      </main>
    </div>
  );
};

export default MainLayout;

