import React, { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import { standarsItemsTeam3 } from '../../utils/standarItems';
import ImprovementPlan from './ImprovementPlan';
import { PlanData } from '../../views/company/Autoevaluation';
import { autoevaluationService } from '../../services/autoevaluationService';
import { customizedItems } from '../../utils/customizedItems';
import Button from '../Button';
import SelectInput from '../SelectInput';

interface CreateTaskModalProps {
    open: boolean;
    onClose: () => void;
}
interface SelectedItem {
    propName: string;
    itemText: string;
    isCustomizedItem: boolean;
}

const itemTypes = [
    { label: "Items estándar", value: "standard" },
    { label: "Items extras", value: "custom" }
];

const CreateTaskModal: React.FC<CreateTaskModalProps> = ({ open, onClose }) => {
    const [selectedItem, setSelectedItem] = useState<SelectedItem | null>(null);
    const [showImprovementPlanModal, setShowImprovementPlanModal] = useState(false);
    const [formDataModal, setFormDataModal] = useState<PlanData[]>([]);
    const [dataReadyToSend, setDataReadyToSend] = useState(false);
    const [itemType, setItemType] = useState<string>('standard');

    const mapToOptions = (items: { propName: string; text: string }[]) => {
        return items.map(item => ({
            value: item.propName,
            label: item.text,
        }));
    };

    const options = itemType === 'standard'
        ? mapToOptions(standarsItemsTeam3)
        : mapToOptions(customizedItems);


    const handleItemChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const selectedItem = itemType === 'standard'
            ? standarsItemsTeam3.find(item => item.propName === value)
            : customizedItems.find(item => item.propName === value);
        if (selectedItem) {
            setSelectedItem({
                propName: selectedItem.propName,
                itemText: selectedItem.text,
                isCustomizedItem: itemType === 'custom'
            });
        }
    };

    const handleOpenImprovementPlanModal = () => {
        if (selectedItem) {
            setShowImprovementPlanModal(true);
            onClose()
        }
    };

    const handleCloseImprovementPlanModal = () => {
        setShowImprovementPlanModal(false);
    };



    const handleImprovementPlanSubmit = (planData: PlanData) => {
        if (selectedItem) {
            const newPlan: PlanData = {
                ...planData,
                is_customized_item: selectedItem.isCustomizedItem
            };

            if (selectedItem.isCustomizedItem) {
                newPlan.customized_item_name = selectedItem.propName;
                newPlan.item_name = undefined; // Ensure item_name is undefined for custom items
            } else {
                newPlan.item_name = selectedItem.propName;
                newPlan.customized_item_name = undefined; // Ensure customized_item_name is undefined for standard items
            }

            setFormDataModal((prev) => {
                const updatedPlans = prev.filter(plan => plan.item_name !== selectedItem.propName && plan.customized_item_name !== selectedItem.propName);
                const newPlans = [...updatedPlans, newPlan];
                setDataReadyToSend(true);
                return newPlans;
            });
        }
    };

    useEffect(() => {
        if (dataReadyToSend && formDataModal.length > 0) {
            autoevaluationService.submitPlanData(
                formDataModal,
                (response) => {
                    onClose()
                    setShowImprovementPlanModal(false);
                    setDataReadyToSend(false); // Reset the flag after submission
                },
                (error) => {
                    console.error('Error al enviar el Plan de Mejora:', error);
                    alert('Error al enviar el Plan de Mejora: ' + error);
                    setDataReadyToSend(false); // Reset the flag if there's an error
                }
            );
        }
    }, [dataReadyToSend, formDataModal]);

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box className="grid place-content-center place-items-center absolute rounded-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 border-2 border-secondaryBrown  shadow-lg text-secondaryBrown max-w-sm">
                    <div className='text-xl mb-4 font-semibold'>Crear Tarea</div>
                    <div className="w-full flex flex-col mb-4 ">
                        <SelectInput
                            label='Tipo de item'
                            id='type-item'
                            value={itemType}
                            onChange={(e) => setItemType(e.target.value)}
                            options={itemTypes}
                        />
                    </div>
                    <div className="w-full flex flex-col mb-4 ">
                        <SelectInput
                            label='Selecciona un item'
                            id='selected-item'
                            value={selectedItem ? selectedItem.propName : ''}
                            onChange={handleItemChange}
                            options={options}
                        />
                    </div>
                    <Button
                        disabled={!selectedItem}
                        onClick={handleOpenImprovementPlanModal}
                        text='Continuar'
                    />

                </Box>
            </Modal>
            {selectedItem && (
                <ImprovementPlan
                    show={showImprovementPlanModal}
                    onClose={handleCloseImprovementPlanModal}
                    itemText={selectedItem.itemText}
                    propName={selectedItem.propName}
                    onSubmit={handleImprovementPlanSubmit}
                    isCustomItem={selectedItem.isCustomizedItem}
                />
            )}
        </>
    );
};

export default CreateTaskModal;
