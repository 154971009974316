import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { EvidenceRequest, EvidenceResponse, uploadImageService } from '../../services/uploadImageServices';
import { useDropzone } from 'react-dropzone';
import { absenceTypes } from '../../utils/AbsenteeismUtils';
import { EmployeesListData, employeesService } from '../../services/employeesService';
import SelectInput from '../SelectInput';
import SelectInputNumber from '../SelectInputNumber';
import TextFieldString from '../TextFieldString';
import TextFieldDate from '../TextFieldDate';
import CancelButton from '../CancelButton';
import Button from '../Button';
import HeaderSurveys from '../HeaderSurveys';
import { AbsenceResponse } from '../TableAbsenteeism';



interface CreateAbsenteeismModalProps {
  open: boolean;
  onClose: () => void;
  onCreate?: (formData: CreateAbsenteeismFormData) => void;
  onUpdate?: (formData: AbsenceResponse) => void;
  createAbsenteeismFormData?: AbsenceResponse | null;
}

export interface CreateAbsenteeismFormData {
  absenceType: string;
  description: string;
  startDate: string;
  endDate: string;
  evidenceUrl: string;
  employee: number;
}



type OptionType = {
  label: string;
  value: number;
};

const CreateAbsenteeismModal: React.FC<CreateAbsenteeismModalProps> = ({ open, onClose, onCreate, onUpdate, createAbsenteeismFormData }) => {
  const [formData, setFormData] = useState<CreateAbsenteeismFormData>({
    absenceType: '',
    description: '',
    startDate: '',
    endDate: '',
    evidenceUrl: '',
    employee: 0,
  });

  const [files, setFiles] = useState<File[]>([]);
  const [evidence, setEvidence] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [employeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    }
  });

  const renderPreview = (file: File | string) => {
    if (typeof file === 'string') {
      // Si es una URL, mostrarla
      if (file.endsWith('.pdf')) {
        return <embed src={file} type="application/pdf" width="150px" height="150px" />;
      } else {
        return <img src={file} alt="evidence-preview" style={{ width: '150px', height: '150px' }} />;
      }
    } else {
      // Si es un archivo, mostrarlo
      const fileType = file.type;
      if (fileType.startsWith('image/')) {
        return <img src={URL.createObjectURL(file)} alt={`preview-${file.name}`} style={{ width: '150px', height: '150px' }} />;
      } else if (fileType === 'application/pdf') {
        return <embed src={URL.createObjectURL(file)} type="application/pdf" width="150px" height="150px" />;
      } else {
        return (
          <div className="p-2 border rounded bg-gray-200" style={{ width: '150px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', textAlign: 'center' }}>
            {file.name}
          </div>
        );
      }
    }
  };

  useEffect(() => {
    employeesService.employeesData(
      (response) => {
        const options = response.results.map((employee: EmployeesListData) => ({
          label: `${employee.first_name} ${employee.last_name}`,
          value: employee.id,
        }));
        setEmployeeOptions(options);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      }
    );
  }, []);

  useEffect(() => {
    if (open && createAbsenteeismFormData) {
      setFormData({
        absenceType: createAbsenteeismFormData.absense_type,
        description: createAbsenteeismFormData.description || '',
        startDate: createAbsenteeismFormData.start_date,
        endDate: createAbsenteeismFormData.end_date,
        evidenceUrl: createAbsenteeismFormData.evidence_url,
        employee: createAbsenteeismFormData.employee_data.id,
      });
      setStartDate(new Date(createAbsenteeismFormData.start_date));
      setEndDate(new Date(createAbsenteeismFormData.end_date));
      setEvidence(createAbsenteeismFormData.evidence_url)

    }
  }, [open, createAbsenteeismFormData]);



  const handleSave = () => {
    const errors = validateForm();
    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    }

    setLoading(true);

    const uploadPromises: Promise<string>[] = files.map((file) => {
      const request: EvidenceRequest = new EvidenceRequest(file.name, formData.employee);

      return new Promise<string>((resolve, reject) => {
        uploadImageService.generateUrl(
          request,
          (response: EvidenceResponse) => {
            const tempEvidenceUrl = response.final_url;
            uploadImageService.uploadImage(
              { file, url: response.url },
              () => resolve(tempEvidenceUrl),
              (error: any) => reject(error)
            );
          },
          (error: any) => reject(error)
        );
      });
    });

    Promise.all(uploadPromises)
      .then((urls) => {
        const updatedFormData = {
          ...formData,
          evidenceUrl: urls.join(', '),
          employee: formData.employee, // Aquí enviamos solo el ID del empleado
        };

        if (createAbsenteeismFormData && createAbsenteeismFormData.id) {
          // Actualizar usando solo el ID del empleado
          onUpdate?.({
            id: createAbsenteeismFormData.id,
            absense_type: updatedFormData.absenceType,
            description: updatedFormData.description,
            start_date: updatedFormData.startDate,
            end_date: updatedFormData.endDate,
            evidence_url: updatedFormData.evidenceUrl,
            employee_data: {
              id: updatedFormData.employee,
              first_name: createAbsenteeismFormData.employee_data.first_name, // Usar el nombre existente
              last_name: createAbsenteeismFormData.employee_data.last_name,   // Usar el apellido existente
            },
          });
        } else {
          onCreate && onCreate(updatedFormData);
        }
        onClose();
      })
      .catch((error) => console.error('Error durante la carga de evidencia:', error))
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  const resetForm = () => {
    setFormData({
      absenceType: '',
      description: '',
      startDate: '',
      endDate: '',
      evidenceUrl: '',
      employee: 0
    });
    setEndDate(null)
    setStartDate(null)
    setFiles([]);
    setValidationErrors([]);
  };

  const validateForm = () => {
    const errors: string[] = [];
    if (!formData.absenceType) errors.push('Tipo de Ausentismo es requerido');
    if (!formData.description) errors.push('Descripción es requerida');
    if (!formData.startDate) errors.push('Fecha de Inicio es requerida');
    if (!formData.endDate) errors.push('Fecha de Fin es requerida');
    if (!files.length) errors.push('Evidencia es requerida');
    if (!formData.employee) errors.push('Empleado es requerido');
    return errors;
  };

  return open ? (
    <div className="fixed zin inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 p-4">
      <div className="bg-white rounded-lg shadow-lg min-h-[700px] w-[500px] border-2 border-secondaryBrown max-h-full
      overflow-y-auto mobile:max-h-screen">
        <HeaderSurveys title="Crear ausentismo" />
        <div className="grid text-start py-4 px-6">
          <SelectInputNumber
            id='empleado-id'
            label='Empleado'
            value={formData.employee}
            options={employeeOptions}
            onChange={(newValue) => {
              const selectedEmployeeId = newValue;
              setFormData((prev) => ({
                ...prev,
                employee: selectedEmployeeId,
              }));
            }}
          />
          <SelectInput
            id='absenteeism-id'
            label='Tipo de ausentismo'
            value={formData.absenceType}
            options={absenceTypes.map(absenceType => ({ value: absenceType, label: absenceType }))}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              absenceType: e.target.value
            }))}
          />
          <TextFieldString
            label="Descripción"
            value={formData.description}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              description: e.target.value
            }))}
          />
          <TextFieldDate
            label="Fecha de inicio"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.valueAsDate)
              setFormData(prev => ({
                ...prev,
                startDate: e.target.value
              }))
            }}
          />
          <TextFieldDate
            label="Fecha de fin"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.valueAsDate)
              setFormData(prev => ({
                ...prev,
                endDate: e.target.value
              }))
            }}
          />
          <div className="mb-4">
            <div className="bg-secundaryYellow hover:bg-primaryYellow font-semibold text-secondaryBrown p-5 text-center" {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? <p>Suelta el archivo aquí ...</p> : <p>Añadir Evidencia</p>}
            </div>
            <div className="grid grid-cols-3 place-content-center place-items-center w-max">
              {files.map((file, index) => (
                <div key={index} className="p-2">
                  {renderPreview(file)}
                </div>
              ))}
            </div>
          </div>
          {evidence && (
            <div className='mb-2 text-center'>
              <a href={evidence} className='text-primaryOrange ' target='blank'>Ver evidencia</a>
            </div>
          )}

          {validationErrors.length > 0 && (
            <Box mb={2}>
              {validationErrors.map((error, index) => (
                <Typography className='text-center' key={index} color="error">
                  {error}
                </Typography>
              ))}
            </Box>
          )}
          <div className='flex justify-around'>
            <Button
              onClick={handleSave}
              disabled={loading}
              text={loading ? 'Creando...' : 'Crear'}
            />
            <CancelButton
              onClick={onClose}
              text='Cancelar' />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default CreateAbsenteeismModal;
