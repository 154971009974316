import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

export interface EvidenceResponse {
    url: string,
    final_url: string
}

export class EvidenceRequest {
    file_name: string;
    employee_id?: number;

    constructor(file_name: string, employee_id?: number) {
        this.file_name = file_name;
        this.employee_id = employee_id;
    }
}

const generateUrl = (
    request: EvidenceRequest,
    onSuccess: (response: EvidenceResponse) => void,
    onError: (error: any) => void,
): void => {
    axios.post<EvidenceResponse>(`${API_URL}/companies/companies/evidence/upload_url`, request,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error))
};

export class UploadRequest {
    file: File;
    url: string;

    constructor(file: File, url: string) {
        this.file = file;
        this.url = url;
    }
}

const uploadImage = (
    request: UploadRequest,
    onSuccess: (response: any) => void,
    onError: (error: any) => void
): void => {
    axios.put(request.url, request.file,
        {
            headers: {
                'Content-Type': request.file.type,
            }
        })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error))
};




export class WorkEvidenceData {
    employee: number;
    url: string;

    constructor(
        employee: number,
        url: string,
    ) {
        this.employee = employee;
        this.url = url;
    }
}

type OnSuccess = (response: any) => void;
type OnError = (error: any) => void;

const workEvidence = (workEvidenceData: WorkEvidenceData, onSuccess: OnSuccess, onError: OnError): void => {
    axios.post(`${API_URL}/companies/auth/evidence`, workEvidenceData,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,  // Agrega el token aquí
                'Content-Type': 'application/json'
            }
        })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

export interface  DataWorkEnv {
    id: number;
    url: string;
    employee: number;
 }

interface ResponseListWorkEnv {
    results: DataWorkEnv[];
 }

 const getworkEvidence = (employeeId: number, onSuccess: (data: ResponseListWorkEnv) => void, onError: (error: any) => void): void => {
    axios.get<ResponseListWorkEnv>(`${API_URL}/companies/auth/work-evidence/`, 
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json'
            },
            params: {
                employee_id: employeeId
            }
        })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

export class CompanyEvidenceData {
    url: string;

    constructor(
        url: string,
    ) {
        this.url = url;
    }
}

const companyEvidence = (companyEvidenceData: CompanyEvidenceData, onSuccess: OnSuccess, onError: OnError): void => {
    axios.post(`${API_URL}/companies/auth/evidence`, companyEvidenceData,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,  // Agrega el token aquí
                'Content-Type': 'application/json'
            }
        })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

export class EvidenceImprovementPlanData {
    item_name?: string;
    url: string;
    employee? : number;

    constructor(
        item_name: string,
        url: string,
        employeeId? : number

    ) {
        this.item_name = item_name;
        this.url = url;
        this.employee = employeeId;
    }
}


const evidenceImprovementPlans = (evidenceImprovementPlansData: EvidenceImprovementPlanData, onSuccess: OnSuccess, onError: OnError): void => {
    axios.post(`${API_URL}/companies/auth/evidence/improvement-plans`, evidenceImprovementPlansData,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

export class EvidenceImprovementPlanDataResponse {
    id: number;
    item_name: string;
    url: string;
    created_at: Date;
    minimum_standards: number;

    constructor(
        id: number,
        item_name: string,
        url: string,
        created_at: Date,
        minimum_standars: number
    ) {
        this.id = id;
        this.item_name = item_name
        this.url = url;
        this.created_at = created_at;
        this.minimum_standards = minimum_standars;
    }
}

export class GetEvidenceImprovementPlansByEmployee {
    itemName: string;
    employeeId?: number;
    
    constructor(
      itemName: string,
      employeeId?: number,  
  
    ) {
      this.itemName = itemName;
      this.employeeId = employeeId;
    }
  }

type OnSuccessDataResponse = (response: EvidenceImprovementPlanDataResponse[]) => void;

const getEvidenceImprovementPlans = (
    getEvidenceImprovementPlansByEmployee:GetEvidenceImprovementPlansByEmployee,  
    onSuccess: OnSuccessDataResponse, 
    onError: OnError
): void => {
    axios.get<EvidenceImprovementPlanDataResponse[]>(`${API_URL}/companies/auth/evidence/improvement-plans`,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json'
            },
            params: {
                item_name: getEvidenceImprovementPlansByEmployee.itemName,
                employee_id: getEvidenceImprovementPlansByEmployee.employeeId,
            }
        })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

export const uploadImageService = {
    generateUrl,
    uploadImage,
    workEvidence,
    companyEvidence,
    evidenceImprovementPlans,
    getEvidenceImprovementPlans,
    getworkEvidence
}

