import ItemMenu from './ItemMenu'
import homeIcon from '../../assets/icons/home-icon.svg'
import homeIconOpen from '../../assets/icons/home-icon-open.svg'
import absenteeismIcon from '../../assets/icons/absenteeism-icon.svg'
import absenteeismIconOpen from '../../assets/icons/absenteeism-icon-open.svg'
import workPlanIcon from '../../assets/icons/work-plan-icon.svg'
import workPlanIconOpen from '../../assets/icons/work-plan-icon-open.svg'
import trainingPlanIcon from '../../assets/icons/training-plan-icon.svg'
import trainingPlanIconOpen from '../../assets/icons/training-plan-icon-open.svg'
import employeesIcon from '../../assets/icons/employees-icon.svg'
import backupDownloadIcon from '../../assets/icons/backup-download-icon.svg'
import employeesIconOpen from '../../assets/icons/employees-icon-open.svg'
import logoutIcon from '../../assets/icons/logout-icon.svg'
import logoutIconOpen from '../../assets/icons/logout-icon-open.svg'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { authService } from '../../services/authServices';
import { setLoggedIn } from '../../features/userSlice';
import { useEffect, useState, useRef } from 'react'
import plusIcon from '../../assets/icons/plus-icon.svg'
import { backupService } from '../../services/backupService'

const Sidebar = () => {
  const [backupStatus, setBackupStatus] = useState<string>('');
  const [backupId, setBackupId] = useState<string>()
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [errorBackup, setErrorBackup] = useState<string | undefined>();
  const [showDownloadWindow, setShowDownloadWindow] = useState<boolean>(false);
  const [showErrorWindow, setShowErrorWindow] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [position, setPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });

  const downloadButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Function to get the position of the download button
    const updatePosition = () => {
      if (downloadButtonRef.current) {
        const rect = downloadButtonRef.current.getBoundingClientRect();
        setPosition({
          top: rect.top,
          left: rect.left + rect.width // Adjust for horizontal positioning next to the button
        });
      }
    };

    // Update position when the component mounts and when the window is resized
    updatePosition();
    window.addEventListener('resize', updatePosition);

    return () => window.removeEventListener('resize', updatePosition);
  }, [showDownloadWindow]);

  const completeLogout = () => {
    dispatch(setLoggedIn(false));
    localStorage.clear();
    navigate('/');
  };

  const handleLogout = () => {
    authService.logout(
      completeLogout,
      (err) => {
        if (err?.response?.status === 401) {
          completeLogout();
        } else {
          alert(`Error durante cierre de sesión: ${err.message || err}`);
        }
      }
    );
  };

  useEffect(() => {

    let intervalId: NodeJS.Timeout | null = null;

    if (backupId) {
      intervalId = setInterval(() => {
        backupService.checkBackupStatus(
          backupId,
          (data) => {
            setBackupStatus(data.status);
            if (data.status === "Error") {
              setErrorBackup(data.error)
              setShowErrorWindow(true);
              clearInterval(intervalId!);
            } else if (data.status === "Creado" && data.download_url) {
              setDownloadUrl(data.download_url);
              clearInterval(intervalId!);
              setShowDownloadWindow(true);
            }
          },
          (error) => {
            console.error('Error checking backup status:', error);
            setBackupStatus('failed');
            clearInterval(intervalId!);
            setShowErrorWindow(true);
          }
        );
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [backupId]);

  const handleBackup = () => {
    backupService.downloadBackup(
      (data) => {
        setBackupId(data.backup_id)
      },
      (error) => {
        console.error('Error downloading file:', error);
      }
    );
  };


  return <div className="bg-white mobile:hidden border-r-[1px] border-primaryOrange h-screen ">
    <div className="w-full flex flex-col gap-5 p-4 items-center justify-center">
      <ItemMenu
        link="/"
        logo={homeIcon}
        selectedLogo={homeIconOpen}
        isSelected={location.pathname === '/' || location.pathname === '/home'}
      />
      <ItemMenu
        link="/absenteeism"
        logo={absenteeismIcon}
        selectedLogo={absenteeismIconOpen}
        isSelected={location.pathname === '/absenteeism'}
      />
      <ItemMenu
        link="/work-plan"
        logo={workPlanIcon}
        selectedLogo={workPlanIconOpen}
        isSelected={location.pathname === '/work-plan'}
      />
      <ItemMenu
        link="/training-plan"
        logo={trainingPlanIcon}
        selectedLogo={trainingPlanIconOpen}
        isSelected={location.pathname === '/training-plan'}
      />
      <ItemMenu
        link="/employees"
        logo={employeesIcon}
        selectedLogo={employeesIconOpen}
        isSelected={location.pathname === '/employees'}
      />

      <div
        className='w-[130px] h-[100px] p-1 bg-secondaryGray'
        ref={downloadButtonRef}
      >
        <div className='flex flex-col justify-center items-center'>
          <button onClick={handleBackup}>
            <img src={backupDownloadIcon} alt="Imagen de descarga" />


          </button>
          <div className='text-sm text-center'>
            {backupStatus === 'Error' ? (
              <div >
                Backup falló
              </div>
            ) : backupStatus === 'En progreso' ? (
              <div>
                Generando...
              </div>
            ) : (
              <>
                {backupStatus === 'Creado' && downloadUrl ? (
                  <div className='grid text-center place-items-center justify-items-center '>
                    <span>Backup creado</span>
                    <button
                      className="ml-2"
                      onClick={() => setShowDownloadWindow(true)}
                    >
                      <img src={plusIcon} alt="" />

                    </button>
                  </div>
                ) : (
                  <span>Descargar Backup</span>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <ItemMenu
        link="/logout"
        logo={logoutIcon}
        selectedLogo={logoutIconOpen}
        isSelected={location.pathname === '/logout'}
        onClick={handleLogout}
      />
    </div>
    {showDownloadWindow && downloadUrl && (
      <div className="absolute z-40 bg-white shadow-lg border border-secondaryBrown rounded-lg p-4 w-[250px]"
        style={{ top: position.top + 10, left: position.left + 10 }} >
        <div className="flex justify-around items-center">
          <a href={downloadUrl} download="backup.zip">
            <button className="text-primaryOrange">Descargar Backup</button>
          </a>
          <button
            onClick={() => setShowDownloadWindow(false)}
            className="text-red-500 font-bold ml-2"
          >
            x
          </button>
        </div>
      </div>
    )}

    {showErrorWindow && errorBackup && (
      <div className="absolute z-40 bg-white shadow-lg border border-secondaryBrown rounded-lg p-4 w-[250px]"
        style={{ top: position.top + 10, left: position.left + 10 }} >
        <div className="flex justify-between items-center">
          <span className="text-primaryRed">{errorBackup}</span>
          <button
            onClick={() => setShowErrorWindow(false)}
            className="text-red-500 font-bold ml-2"
          >
            x
          </button>
        </div>
      </div>
    )}

  </div>

}

export default Sidebar;
