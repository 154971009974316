import React, { useEffect, useState } from 'react';
import logoLaborus from '../../assets/icons/logoLaborus.svg';
import Notifications from '../Notifications';
import UserMenu from '../UserMenu';
import ItemTextNumber from '../ItemTextNumber';
import { companyService } from '../../services/companyServices';

type Props = {};

const Header = (props: Props) => {
  const [numEmployees, setNumEmployees] = useState<number>(0)
  const [riskLevel, setRiskLevel] = useState<string>('')

  useEffect(() => {

    companyService.getCompanyData(
      (response) => {
        console.log('Get company:', response.number_of_employees);
        setRiskLevel(response.risk_level)
        setNumEmployees(response.number_of_employees)

      },
      (error) => {
        console.error('Error al buscar la company:', error);
      },

    )

  }, [])

  const getMinimumStandards = (numberEmployees: number) => {
    if (numberEmployees < 11) {
      return 7;
    } else if (numberEmployees >= 11 && numberEmployees < 50) {
      return 21;
    } else {
      return 60;
    }
  };

  return (
    <header className="grid bg-[#fdfced] min-h-[217px]  text-center max-w-[1740px] top-0   z-30 border-b-2 border-[#70410433]">
      <div className="flex flex-wrap items-center justify-between w-full mx-4 mt-4">
        <div className="flex items-center gap-4">
          <div className="flex-col text-5xl text-start text-primaryOrange">
            <p className="mb-2">
              <strong>BIENVENIDO</strong>
            </p>
            <p>{localStorage.getItem('company_name') ?? ''}</p>
          </div>
        </div>

        <div className="flex-grow flex items-center justify-end gap-10">
          <div className="flex items-center gap-4 flex-shrink-0">
            <img alt="Logo Laborus" src={logoLaborus} className="h-[90px]" />
          </div>
          <div className="flex items-center gap-6 mobile:gap-2">
            <div className="mobile:flex hidden">
              <UserMenu />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap justify-between m-4 '>
        <ItemTextNumber text='Estandares mínimos' number={getMinimumStandards(numEmployees).toString()} />
        <ItemTextNumber text='Numero de trabajadores' number={numEmployees.toString()} />
        <ItemTextNumber text='Nivel de riesgo' number={riskLevel} />
        <Notifications />
      </div>
    </header>
  );
};

export default Header;

